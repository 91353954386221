export default {
    "seo": {
        "title": "Согласие на обработку персональных данных",
        "description": "Банк 131 — цифровой банк, платежные решения для бизнеса: прием платежей онлайн, выплаты и расчеты с физическими лицами и самозанятыми. Лицензия ЦБ РФ, официальный партнер ФНС."
    },
    "consentProcessingBlock": {
        "title": "Согласие на обработку персональных данных",
        "text":
        "<p>Я, в соответствии с требованиями Федерального закона от 27.07.2006г. № 152-ФЗ «О персональных данных», выражаю свое согласие Акционерному обществу «Банк 131», юридический адрес: 420012, Российская Федерация, Республика Татарстан, г. Казань, ул. Некрасова, д. 38 (далее – Банк) на обработку моих персональных данных с использованием средств автоматизации и (или) без использования таких средств, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, указанных при оформлении обращения на сайте Банка (фамилия, имя, отчество, адрес электронной почты, телефон, а также наименование ЮЛ/ИП и ИНН, в случае подачи обращения от имени ЮЛ/ИП), а также иных сведений, ставших известными Банку и (или) предоставленными мной Банку в процессе рассмотрения моего обращения в целях:</p>" +

        "<ul>" +

        "<li>рассмотрения обращения и направления/предоставления ответа (для достижения указанной цели Банк осуществляет проверку достоверности указанных мною сведений; запросы дополнительных сведений и документов, необходимых для всестороннего и объективного рассмотрения обращения);</li>" +

        "<li>улучшения качества обслуживания, банковских продуктов, предлагаемых Банком;</li>" +

        "<li>обеспечения полноценного функционирования, улучшения работы сайта Банка в информационно-телекоммуникационной сети «Интернет», учета предпочтений пользователей.</li>" +

        "</ul>" +

        "<p>Согласие предоставляется с момента создания настоящего обращения и действует в течение 5 (пяти) лет с момента направления/предоставления Банком ответа по результатам рассмотрения обращения.</p>" +

        "<p>Согласие может быть отозвано в соответствии с п. 2 ст. 9 Федерального закона от 27.07.2006 г. № 152 «О персональных данных» путем предоставления в Банк соответствующего заявления в простой письменной форме, либо путем направления этого заявления заказным письмом Почтой РФ на юридический адрес Банка. При этом Банк вправе продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в п. 2-11 ч. 1 ст. 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</p>" +

        "<p>Я согласен(-на) с тем, что Банк в целях уточнения информации, содержащейся в обращении, и информирования о ходе рассмотрения настоящего обращения может использовать сведения, содержащиеся в настоящем электронном обращении.</p>" +

        "<p>Я уведомлен(-на), что электронные обращения, направленные через сеть Интернет, передаются по незащищенным каналам связи. Банк не несет ответственности за сохранение конфиденциальности данных при их передаче через сеть «Интернет».</p>"
    }
}
