export default {
    "seo": {
        "title": "Consent to personal data processing",
        "description": "Bank 131 – Russia’s newest digital bank. API-based compliant payment solution for Russia, mass payouts, payouts to the self-employed, online payments, and banking as a service."
    },
    "consentProcessingBlock": {
        "title": "Consent to personal data processing",
        "text":
        "<p>I, in accordance with the requirements of the Federal Law No. 152-FZ \"On Personal Data\" dated 27/07/2006, agree that Bank 131 Joint-Stock Company, legal address: 420012, 38 Nekrasova street, Kazan, Republic of Tatarstan, Russian Federation (hereinafter referred to as the Bank) processes my personal data using automation tools and (or) without using such tools, including collection, recording, systematization, accumulation, storage, improvement (updating, amendment), extract, use, transfer (provision, access), depersonalization, blocking, deletion, destruction of personal data specified when submitting an application on the website of the Bank (last name, first name, patronymic, email, telephone, as well as name of legal entity / individual entrepreneur and INN (Taxpayer Identification Number), if the application is submitted on behalf of the legal entity / individual entrepreneur), as well as other data that became known to the Bank and (or) provided by me to the Bank during considering my application to:</p>" +

        "<ul>" +

        "<li>consider the application and send /provide a response (in order to achieve the specified goal, the Bank verifies the accuracy of the information I have provided, requests additional information and documents necessary for a comprehensive and objective consideration of the application);</li>" +

        "<li>improve service quality, banking products offered by the Bank;</li>" +

        "<li>ensure full functioning, improving the work of Bank's website in the information and telecommunications Internet network, taking into account users’ preferences.</li>" +

        "</ul>" +

        "<p>Consent is given from the moment of this request creation and is valid for 5 (five) years from the moment of sending / providing the Bank with a response based on the results of consideration of the request.</p>" + 

        "<p>Consent may be revoked in accordance with paragraph 2 of Article 9 of the Federal Law No. 152 \"On Personal Data\" dated 27/07/2006 by submitting a corresponding application in simple written form to the Bank, or by sending this application by registered mail by Russian Post to the legal address of the Bank. In this case, the Bank has the right to continue personal data processing without my consent if there are grounds specified in paragraphs 2-11 of Part 1 of Article 6 of the Federal Law No. 152-FZ \"On Personal Data\" dated 27/07/2006.</p>" + 

        "<p>I agree that the Bank may use the information contained in this electronic request for the purpose of clarifying the information contained in the request and informing about progress of consideration of this request.</p>" + 

        "<p>I am aware that electronic requests sent via the Internet are transmitted via unsecured communication channels. The Bank is not responsible for maintaining data confidentiality when transmitting it via the Internet.</p>"
    }
}
